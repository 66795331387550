.mb-40{
    margin-bottom: 40px;
}
.mb-32{
    margin-bottom: 32px;
}
.mb-24{
    margin-bottom: 24px;
}
.mb-45{
    margin-bottom: 45px;
}
.mb-60{
    margin-bottom: 60px;
}
.mt-45{
    margin-top: 45px;
}
.mt-80{
    margin-top: 80px;
}

.minw-300{
    min-width: 300px;
}

@media (max-height: 900px) and (max-width: 1440px) {
    .mb-60{
        margin-bottom: 30px;
    }
    .mb-40{
        margin-bottom: 15px;
    }
    .mb-24{
        margin-bottom: 10px;
    }
    .mt-45{
        margin-top: 25px;
    }
    .mt-80{
        margin-top: 40px;
    }
}

.z-index-1{
    z-index: 1;
}
.z-index-2{
    z-index: 2;
}
.z-index-3{
    z-index: 3;
}
.z-index-4{
    z-index: 4;
}
.z-index-5{
    z-index: 5;
}
.z-index-6{
    z-index: 6;
}