@import "colors";

.btn {
    padding: 12px 20px;
    font-family: "Montserrat-Medium", sans-serif;
    font-size: 14px;
    border-radius: 12px;
    transition: all 500ms;
    color: $gold;
    max-width: 100%;
    cursor: pointer;
    &.btn-primaty {
        &:focus,
        &:active,
        &:hover {
            background-color: $primary;
        }
    }
    &.btn-light {
        background-color: transparent;
        border: 1px solid $light;
        color: $text-color;
    }
    &.btn-primary2 {
        background-color: $primary2;
        border: 1px solid $light;
    }
    &.btn-secondary {
        color: $primary;
        &:focus,
        &:active,
        &:hover {
            color: $primary;
        }
    }
    &.btn-send{
        min-width: 46px;
        min-height: 46px;
        border-radius: 50%;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        color: $primary;
        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
            outline: none;
        }
    }

    &.btn-social{
        min-width: 46px;
        min-height: 46px;
        border-radius: 50%;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        color: white;
        background-color: $primary;
        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
            outline: none;
        }
    }

    &.btn-cart{
        min-width: 46px;
        min-height: 46px;
        border-radius: 10px;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        background-color: $primary;
        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
            outline: none;
        }
    }

    &.btn-audio{
        min-width: 46px;
        min-height: 46px;
        border-radius: 50%;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        background-color: $grey14;
        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
            outline: none;
        }
    }

    &.btn-plus{
        min-width: 30px;
        min-height: 30px;
        border-radius: 10px;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        background-color: $secondary;
        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
            outline: none;
        }
    }

    &.btn-qty{
        min-width: 24px;
        min-height: 24px;
        border-radius: 6px;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        background-color: $secondary;
        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
            outline: none;
        }
    }

    & + & {
        margin-left: 14px;
    }

    &:focus,
    &:active,
    &:hover {
        cursor: pointer;
        color: $gold;
    }
    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.link {
    text-decoration: none;
    color: $secondary;
}
