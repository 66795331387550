.profile {
    .section1 {
        .btn{
            min-width: 300px;
        }
        .user {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            border: 1.5px solid $primary;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
    }

    @media only screen and (max-width: 766px) {
        .section1 {
            .btn{
                min-width: 100%;
            }
            .btn + .btn {
                margin-left: 0;
            }
        }
    }
}
