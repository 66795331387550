.offer-item {
  border-radius: 10px;
  margin-bottom: 30px;
  transition: 600ms;
  background-color: $white;
  box-shadow: 1px 2px 7px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  &:hover {
    box-shadow: 4px 4px 16px 5px rgba(54, 1, 63, 0.2);
    cursor: pointer;
  }
  .img {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    color: white;
    background-color: $grey5;
  }
  .offer-content {
    padding: 15px;
    .title {
      color: $black;
    }
    .description {
      color: $black;
    }
    .old-price {
      font-weight: 300;
      text-decoration: line-through;
      font-family: "Montserrat-Regular", sans-serif;
      font-size: 12px;
      color: $black;
    }
  }
  .discount {
    padding: 5px 15px;
    border-radius: 6px;
    color: #fff;
    background-color: $green;
    position: absolute;
    right: 5px;
    top: -7px;
    font-size: 14px;
    font-family: "Montserrat-Bold", sans-serif;
  }
  &.large {
    margin-bottom: 0px;
    min-width: 300px;
    .offer-content {
      padding: 30px 30px;
      position: relative;
      .title {
        color: $primary;
      }
      .price {
        color: $gold;
        .old-price {
          color: $primary;
        }
      }
      .description {
        color: $grey;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        max-height: 100px;
      }
      .discount {
        padding: 7px 20px;
        border-radius: 14px;
        color: #fff;
        background-color: $red;
        position: absolute;
        right: 40px;
        top: -18px;
        font-size: 18px;
        font-family: "Montserrat-Bold", sans-serif;
        box-shadow: 1px 2px 7px 3px rgba(0, 0, 0, 0.1);
      }
    }
    .close {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.4);
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 99;
    }
    .img {
      height: 40vh;
    }
  }
  @media only screen and (max-width: 756px) {
    .img {
      height: 150px;
    }
  }
}

.my-offer-item {
  transition: 600ms;
  background-color: $white;
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px solid $primary2;
  margin: 0 calc(var(--bs-gutter-x) * -0.5);

  .img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    background-color: $grey5;
    margin-right: 10px;
    display: inline-block;
  }
  .my-offer-content {
    width: calc(100% - 140px);
    margin-right: 5px;
    display: inline-block;
    vertical-align: top;
    .title {
      color: $black;
    }
    .description {
      color: $black;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      max-height: 40px;
      margin-bottom: 0;
    }
  }
  .my-offer-right {
    max-width: 90px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    .btn {
      font-size: 8px;
      padding: 2px 4px;
      text-transform: uppercase;
    }
    .price {
      font-size: 16px;
      color: $gold;
    }
    .old-price {
      font-size: 10px;
      color: $primary2;
      text-decoration: line-through;
    }
  }
  @media only screen and (max-width: 756px) {
    .img {
    }
  }
}

.shop-item {
  border-radius: 25px;
  margin-bottom: 10px;
  transition: 600ms;
  min-height: 250px;
  box-shadow: 1px 2px 7px 3px rgba(0, 0, 0, 0.1);
  background-size: 40%;
  background-position: center right;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  cursor: pointer;
  &.Purple {
    background-color: $primary;
  }
  &.Gold {
    background-color: $gold;
    .title {
      color: $primary;
    }
    .description {
      color: $primary;
    }
    .time {
      color: $primary;
    }
  }
  &:hover {
    box-shadow: 4px 4px 16px 5px rgba(54, 1, 63, 0.2);
    cursor: pointer;
  }
  .title {
    color: $white;
  }
  .description {
    color: $white;
    font-size: 12px;
  }
  .time {
    color: rgba(255, 255, 255, 0.8);
  }
  @media only screen and (max-width: 756px) {
    padding: 15px;
    min-height: 190px;
    background-size: 65%;
    .time,
    .description {
      font-size: 10px;
    }
  }
}

.custom-toast-con {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 30px;
  transform: translateX(200%);
  transition: all 100ms;
  z-index: 9999999;
  &.visible {
    transform: translateX(-60px);
  }
  .custom-toast {
    width: 400px;
    padding: 20px 30px;
    background-color: #ddd;
    border-radius: 10px;
    box-shadow: 10px 13px 47px 0 rgba(0, 0, 0, 0.11);
    color: white;
    .title {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 0;
    }
    .subtitle {
      font-family: "Montserrat-Medium", sans-serif;
      font-size: 14px;
      line-height: 14px;
    }
    &.danger {
      background-color: red;
    }
    &.success {
      background-color: green;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 15px;
    width: 100%;
    &.visible {
      transform: translateX(0px);
    }
    .custom-toast {
      width: 100%;
    }
  }
}

.header {
  padding: 15px 0;
  .hotel-img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 1px solid $primary;
    background-color: $primary;
    background-image: url(../images/snafi_icons.png);
    background-position: center right;
    background-size: 94%;
    background-repeat: no-repeat;
  }
  .logo {
    max-width: 100px;
  }
  .user {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 1.5px solid $primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 991px) {
  }
}

footer.footer {
  display: flex;
  border-radius: 15px 15px 0 0;
  justify-content: space-between;
  box-shadow: 0px -6px 47px 0 rgba(0, 0, 0, 0.11);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  a {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    transition: all 500ms;
    svg {
      color: $grey13;
    }
    &.logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $primary;
      transform: scale(1.2) translateY(-10px);
      box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.29);
      img {
        width: 43px;
        margin-left: 3px;
      }
    }
    &.active {
      transform: scale(1.2);
      svg {
        color: $primary;
      }
    }
  }

  @media only screen and (max-width: 991px) {
  }
}

.message-item {
  border-radius: 10px;
  background-color: white;
  margin-bottom: 10px;
  transition: border 500ms;
  position: relative;
  overflow: hidden;
  border: 1px white solid;
  vertical-align: top;
  background-color: $grey14;
  border-radius: 25px;
  padding: 10px 20px;
  .img {
    height: auto;
    width: 50px;
    filter: invert(100%) sepia(100%) hue-rotate(180deg);
    margin-right: 10px;
  }
  .message-content {
    display: inline-block;
    width: calc(100% - 10px);
    max-width: 500px;
    color: $grey;
    font-size: 10px;
    margin-bottom: 5px;
    text-align: left;
    &.is-demmand {
      width: calc(100% - 60px);
    }
  }
  .time {
    display: block;
    font-size: 9px;
    color: $grey3;
    //margin-left: 42px;
  }
  &.sender {
    background: $primary2;
    .message-content {
      color: white;
    }
    .time {
    }
  }
}

.category-item {
  border-radius: 10px;
  transition: all 600ms;
  background-color: $gold-light;
  position: relative;
  padding: 12px 20px;
  cursor: pointer;
  margin-left: 12px;
  display: inline-block;
  &.active {
    background-color: $gold;
    .name {
      color: $primary;
      font-family: "Montserrat-Bold", sans-serif;
    }
  }
  .img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    background-color: $primary;
    display: inline-block;
    vertical-align: middle;
  }
  .name {
    color: $primary2;
    display: inline-block;
    vertical-align: middle;
  }
}

.product-item {
  border-radius: 30px;
  transition: all 600ms;
  background-color: $primary2;
  box-shadow: 1px 2px 7px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  &.full-screen {
    width: 93vw;
    z-index: 2;
    bottom: 20vh;
    background-color: $primary2;
    .img {
      height: 300px;
    }
  }
  .img {
    height: 200px;
    width: 100%;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    color: white;
    background-color: $grey5;
    transition: all 600ms;
  }
  .product-content {
    padding: 20px;
    transition: all 600ms;
    .title {
      color: $white;
    }
    .description {
      color: #fff;
      font-weight: 300;
      font-size: 10px;
    }
    .price {
      font-size: 16px;
      color: $white;
    }
  }
  &.large {
    .img {
      height: 250px;
    }
    .close {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.4);
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 99;
    }
  }
  &.horizontal {
    padding: 20px 20px 15px;
    box-shadow: none;
    .product-content {
      width: calc(100% - 90px);
      display: inline-block;
      vertical-align: top;
      padding: 0;
    }
    .img {
      height: 66px;
      width: 66px;
      border-radius: 16px;
      border: 2px solid $gold;
      display: inline-block;
      margin-right: 10px;
    }
  }
  &.cart {
    padding: 15px 15px 10px;
    .product-content {
      width: calc(100% - 90px);
    }
    .title {
      font-size: 14px;
    }
    .price {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 756px) {
    .img {
      height: 150px;
    }
  }
}

.cus-modal-container {
  .product-item {
    transform: scale(0.2);
    transition: transform 1000ms;
  }
  &.shown {
    .product-item {
      transform: scale(1);
    }
  }
}

.add-to-cart-con {
  background-color: $primary2;
  border-radius: 30px;
  .add-to-cart {
    padding: 12px 20px;
    border-radius: 30px;
    background-color: $white;
    .options {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      flex-direction: row;
      padding-top: 20px;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      margin: 0 calc(var(--bs-gutter-x) * -0.5);
    }
  }
}

.option-item {
  border-radius: 10px;
  transition: all 600ms;
  background-color: $gold-light;
  position: relative;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  &.active {
    background-color: $gold;
    .name {
      color: $primary;
      font-family: "Montserrat-Bold", sans-serif;
    }
  }
  .name {
    color: $primary2;
  }
}

.option-child-item {
  border-radius: 10px;
  transition: all 600ms;
  background-color: #18301881;
  position: relative;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  &.active {
    background-color: $primary;
    .name {
      color: $gold;
      font-family: "Montserrat-Bold", sans-serif;
    }
  }
  .name {
    color: $white;
  }
}

.quantity-input {
  span {
    color: $white;
    padding: 4px 10px;
    font-family: "Montserrat-Bold", sans-serif;
  }
}

.loader {
  .logo {
    width: 50px;
    height: 50px;
    animation: vibrating 2s infinite;
  }
}

@keyframes vibrating {
  0% {
    transform: rotate(-50deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(50deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-50deg);
  }
}

.auth-modal-con {
  background-color: $white;
  border-radius: 30px;
  padding: 20px 0;
  form {
    padding: 12px 20px;
    border-radius: 30px;
  }
}

.request-item {
  text-align: center;
  .item-icon {
    height: 70px;
    width: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: hue-rotate(152deg) saturate(0);
    display: inline-block;
    vertical-align: middle;
    margin: 10px 15px;
  }
}

.audio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  background: transparent;
  .waveform {
    width: calc(100% - 70px);
    height: 60px;
    position: relative;
    & > wave {
      position: absolute !important;
      left: 0;
      right: 0;
      top: 0;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: $primary;
    border-radius: 50%;
    margin-right: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      background: $primary;
      color: $gold-light;
    }
    &.sender {
      background: $gold-light;
      &:hover {
        background: $gold-light;
        color: $primary;
      }
    }
  }
}

.viewer {
  background-color: red;
}
