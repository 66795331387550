.login {
    .section1 {
    }

    @media only screen and (max-width: 768px) {
        .section1 {
            background-position: top;
        }
    }
}
