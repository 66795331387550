.room {
    padding-bottom: 100px;
    overflow: hidden;
    .section1 {
        .room-btn {
            text-align: left;
            line-height: 1;
            vertical-align: middle;
            padding: 5px 10px;
            display: flex;
            align-items: center;
        }
        .search-con {
            .form-group {
                width: 100%;
                position: relative;
                .form-control {
                    padding-left: 40px;
                    &::placeholder {
                        color: $primary2;
                    }
                }
                .search {
                    position: absolute;
                    left: 10px;
                    top: 13px;
                }
            }
            .btn {
                position: relative;
                .badge {
                    background-color: $gold;
                    min-width: 20px;
                    min-height: 20px;
                    vertical-align: middle;
                    text-align: center;
                    border-radius: 50%;
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    padding: 4px 0;
                    color: $primary;
                    font-size: 12px;
                }
            }
        }
        .categories,
        .populars,
        .meals {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            flex-direction: row;
            width: calc(100% + 24px);
            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;
            margin: 0 calc(var(--bs-gutter-x) * -0.5);
            padding-top: 20px;
            padding-bottom: 20px;
            &.disable-scroll{
                overflow: inherit;
                &::after{
                    content: "";
                    position: fixed;
                    top:0;
                    bottom:0;
                    left:0;
                    right:0;
                    background-color: rgba(0, 0, 0, 0.5);
                    z-index: 1;
                }
            }
        }
        .populars,.meals{
            margin: 0 calc(var(--bs-gutter-x) * -1.5);
            padding: 20px 6px;
        }
        .categories{
            display: inline-block;
            white-space: nowrap;
        }
    }

    @media only screen and (max-width: 575px) {
        .section1 {
        }
    }
}
