@import "colors";

.cus-modal-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 100ms, transform 1000ms;
    transform: translateY(100vh);
    padding: 12px;
    z-index: 9999;
    &.shown {
        transition: opacity 1000ms, transform 100ms;
        opacity: 1;
        transform: translateY(0vh);
    }
    .cus-modal-content {
        z-index: 999;
        min-width: 450px;
        max-height: 80vh;
        overflow: auto;
    }
    &.offers-modal{
        padding: 0;
        background-color: rgba(0, 0, 0, 0.7);
       
    }
    @media only screen and (max-width: 991px) {
        .cus-modal-content {
            min-width: 100%;
        }
    }
}
