$primary: #183018;
$primary2: #183018;
$secondary: #f7e7a9;
$light: #183018;
$primary-transparent: rgba(205, 52, 39, 0.2);
$dark: #1e1f28;
$text-color: #183018;
$black2: #1d1d1d;
$background: #ffffff;
$yellow: #ffd900;
$gold: #f7e7a9;
$gold-light: #f7e7a9;
$grey: #575755;
$grey2: #525a6e;
$grey3: #575756;
$grey4: #f8f8fb;
$grey5: #adadad;
$grey6: #dbdee6;
$grey7: #fafafc;
$grey8: #e9ebf0;
$grey9: #dbe6ee;
$grey10: #ebf1fa;
$grey11: rgba(166, 166, 166, 0.2);
$grey12: #999aa6;
$grey13: #d1d1d1;
$grey14: rgba(223, 223, 223, 0.6);
$grey15: #9aaab7;
$green: #b8ea48;
$green2: rgba(226, 235, 239, 0.5);
$green3: #5ecb97;
$dark-transparent: rgba(30, 31, 40, 0.3);
$green-transparent: rgba(128, 173, 178, 0.3);
$green-transparent2: rgba(128, 173, 178, 0.2);
$lightblue: #6e8eff;
$darkblue: #2c3554;
$green4: #e2ebef;
$pink: #fffbfb;
$red: #ea4848;
