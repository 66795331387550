#AssistanceChat {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;

  .cs-message-list {
    background-color: #fff;
    overflow-y: scroll;
    height: 100vh;
  }

  .cs-message-list {
    border: none;
    background-size: 100%;
    background-position: center;
  }

  .cs-message__content-wrapper .cs-message__content {
    background-color: #183018;
    color: #fff;
    margin-top: 20px;
    border-radius: 25px;
  }

  .cs-message--outgoing .cs-message__content {
    border-radius: 25px;
  }

  .cs-chat-container .cs-message-input {
    border-top: none;
  }

  .cs-main-container {
    border: none;
  }

  .cs-typing-indicator__text {
    color: #183018;
  }

  .cs-typing-indicator__dot {
    background-color: #183018;
  }

  .cs-chat-container {
    height: 83vh;
    z-index: 0;
  }

  .cs-message-input__content-editor-wrapper {
    background-color: #f7e7a9;
    margin-left: 10px;
    border-radius: 50px;
    margin-top: 7px;
  }

  .cs-message-input__content-editor-wrapper .cs-message-input__content-editor {
    color: #183018;
    height: 27px;
    line-height: 25px;
    font-size: 14px;
    background-color: #f7e7a9;
  }

  .cs-chat-container .cs-message-input {
    border-radius: 0 0 18px 18px;
    background-size: 100%;
    background-position: center;
  }

  .cs-message-input__content-editor-wrapper
    .cs-message-input__content-editor::placeholder {
    color: red;
  }

  .message-input {
    background-color: #fff;
  }

  .cs-message-input__tools:first-child .cs-button:last-child {
    display: none;
  }

  .cs-message-input__tools:last-child .cs-button:last-child {
    color: #183018;
  }
}

.cs-typing-indicator__indicator {
  color: #183018;
}
