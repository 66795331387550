@import "colors";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-label,.form-check-label {
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 5px;
}
.form-control {
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 14px;
    border: solid 0.3px $gold-light;
    background-color: $gold-light;
    border-radius: 16px;
    padding: 12px 14px;
    transition: all 500ms;
    &::placeholder {
        color: $primary2;
    }
    &:focus {
        box-shadow: none;
        background-color: $gold-light;
        border-color: $primary;
    }
    &.is-invalid
    {
        background-image: none;
    }
}
.invalid-feedback{
    text-align: left;
    span 
    {
        margin-right: 5px;
    }

    > a
    {
        color: $text-color !important;
        text-decoration: underline !important;
        font-weight: 700;
        cursor: pointer;
    }
}
.i-text
{
    color: $grey3;
    font-size: 11px;
    margin: 7px 0 0 0;
    text-align: left;
}
.dateInput {

    input[type="date"]::-webkit-calendar-picker-indicator {
        color: transparent;
        top: 0;
        right: 0;
        background-image: url(../images/calendar_icon.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        width: 25px;
        height: 25px;
        padding: 0;
        margin: 0;
        
    }
    input[type="date"]:before {
        color: transparent;
        top: 0;
        right: 0;
        background-image: url(../images/calendar_icon.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        width: 25px;
        height: 25px;
        padding: 0;
        margin: 0;
    }
}

.form-check-input {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: 0;
    margin-right: 7px;
    border-color: $primary2;
    
    &[type=checkbox]{
        border-radius: 50%;
        border-color: $primary2;

    }
    &:checked {
        border-radius: 50%;
        background-color: $primary;
        border-color: $primary2;

    }
    &:focus,
    &:active,
    &:hover {
        box-shadow: none;
        outline: 0;
        border-color: $primary2;
    }
}

.hidden-input{
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0;
    z-index: -1;
    position: absolute;
    bottom: 0;
}

.form-check-label{
    color: $primary2;
}