.home {
    .section1 {
    }

    @media only screen and (max-width: 575px) {
        .section1 {
            .offers {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                flex-direction: row;
                width: calc(100% + 24px);
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none;
            }
        }
        .section2 {
            .facilities {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                flex-direction: row;
                width: calc(100% + 24px);
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none;
                margin-left: -12px;
                padding-left: 10px;
            }
        }
    }
}
