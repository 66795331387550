.cart {
    .section1 {
    }

    .section2 {
        background-color: $gold;
        border-radius: 20px 20px 0 0;
        .number {
            background-color: $primary2;
            border-radius: 15px;
            padding: 10px 20px;
            display: inline-block;
            color: $gold;
        }
        .summary{
            background-color: $white;
            border-radius: 20px 20px 0 0;
            .divider{
                width: 100%;
                border-top: 2px dashed $gold;
            }
        }
    }

    @media only screen and (max-width: 766px) {
        .section1 {
        }
    }
}
