.payment {
    .section1 {
        .total {
            background-color: $primary2;
            border-radius: 20px;
            padding: 20px 30px;
            h5 {
                color: $gold;
            }
            h2 {
                color: $white;
            }
        }
    }

    @media only screen and (max-width: 766px) {
        .section1 {
        }
    }
}
