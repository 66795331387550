.chat {
    .section1 {
        .btn {
            vertical-align: middle;
            line-height: 28px;
        }
        .divider {
            background-color: $grey6;
            height: 1px;
            width: 200px;
            margin: 10px 0;
        }
        .chat-content {
            height: calc(100vh - 300px);
            overflow-y: auto;
        }
        .chat-actions {
            position: absolute;
            bottom: 80px;
            right: 0;
            left: 0;
            background: white;
            .form-group {
                position: relative;
                width: 100%;
                .form-control {
                    border-radius: 30px;
                    &::placeholder {
                        color: $grey5;
                    }
                }
                .btn-send {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            .audio-progress {
                transition: all 500ms;
                padding: 8px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                height: 46px;
                width: 0;
                border-radius: 30px;
                overflow: hidden;
                &.recording {
                    z-index: 1;
                    width: 100%;
                    background-color: $gold-light;
                }
            }
            .audio-con {
                vertical-align: top;
            }
        }
    }

    @media only screen and (max-width: 766px) {
        .section1 {
        }
    }
}
