.offers {
    .section1 {
        
    }

    @media only screen and (max-width: 575px) {
        .section1 {
            .offer-item{
                .img{
                    height: 120px;
                }
                .offer-content{
                    padding: 10px;
                }
                .btn-primary{
                    font-size: 10px;
                    padding: 10px;
                }
            }
        }
    }
}
