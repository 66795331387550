@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
}


@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
}

